var generic = window.generic || {};

(function signUp($) {
  var contentBlockSignup = {
    setup: function (context) {
      var self = this; /* eslint no-shadow: ["error", { "allow": ["done"] }] */

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var $modules = $('.js-content-block-signup', context);
      var $emailForm = $();
      var $emailInput = $();
      var $emailSuccessMessage = $();
      var $emailErrorMessage = $();
      var $emailAlreadySignedUpMessage = $();
      var errorMessage = '';
      var successMessage;
      var alreadySignedUpMessage = '';
      var params = {};
      var $thisModule;
      var $languageIdInput = $();
      var selectedLocale = '';
      var languageSettings = [];
      var languageId = '';

      if ($modules.length === 0) {
        return;
      }

      $modules.each(function () {
        $thisModule = $(this);
        $emailForm = $thisModule.find('.js-content-block-signup-form');
        $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailForm);
        $emailSuccessMessage = $('.js-content-block-signup-success', $emailForm);
        $emailErrorMessage = $('.js-content-block-signup-error', $emailForm);
        $emailAlreadySignedUpMessage = $('.js-content-block-signup-already-signed-up', $emailForm);
        errorMessage = $thisModule.data('errorText') || '';
        alreadySignedUpMessage = $thisModule.data('signedUpText') || '';
        $languageIdInput = $('input[name="LANGUAGE_ID"]', $emailForm);
        selectedLocale = generic.cookie('LOCALE');
        languageSettings = Drupal.settings && Drupal.settings.common && Drupal.settings.common.translation_locales;

        // Updating the Language ID after the site is translated.
        if (typeof selectedLocale !== 'undefined' && languageSettings) {
          languageId = languageSettings[selectedLocale];

          if (languageId) {
            $languageIdInput.val(languageId);
          }
        }

        $emailInput.once().on('keyup', function emailInput() {
          var $currentElement = $(this);

          $currentElement.val().trim() === ''
            ? $currentElement.addClass('non-empty')
            : $currentElement.removeClass('non-empty');
        });

        $emailForm.once($emailForm).on('submit', function errorMsg(event) {
          event.preventDefault();
          $emailForm.removeClass('content-block-signup--active-error');
          $emailSuccessMessage
            .add($emailErrorMessage)
            .add($emailAlreadySignedUpMessage)
            .addClass('hidden');
          $emailInput.removeClass('error');

          params.PC_EMAIL_PROMOTIONS = 0;
          $emailForm.serializeArray().forEach(function (keyVal, index) {
            params[keyVal.name] = keyVal.value.replace('undefined', '').replace('%40', '@');
          });
          /* eslint no-underscore-dangle: ["error", { "allow": ["_TOKEN"] }] */
          if ($.cookie('csrftoken')) {
            params._TOKEN = $.cookie('csrftoken');
          }

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var isSignedUp =
              response.userinfo ? response.userinfo.previously_opted_in_email_promotion : false;

              $emailForm.addClass('content-block-signup--active-success');

              if (isSignedUp) {
                $emailAlreadySignedUpMessage.removeClass('hidden').html(alreadySignedUpMessage);
              } else {
                $emailSuccessMessage.removeClass('hidden').html(successMessage);
              }
              $emailInput.val('').removeClass('non-empty');
              $(document).trigger('contentBlock.signupSuccess');
            },
            onFailure: function (jsonRpcResponse) {
              var errObjs = jsonRpcResponse.getMessages();
              var errors = '';
              var iterate;
              var myErr;
              var errorObjectLength = errObjs.length;

              $emailErrorMessage.removeClass('hidden');

              for (iterate = 0; iterate < errorObjectLength; iterate++) {
                myErr = errObjs[iterate];
                if (myErr && myErr.key) {
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup'
                  ) {
                    errors += myErr.text;
                    $emailInput.addClass('error').focus();
                  }
                }
              }
              if (errorMessage) {
                $emailForm.addClass('content-block-signup--active-error');
                $emailErrorMessage.html(errorMessage);
              } else if (errors) {
                $emailForm.addClass('content-block-signup--active-error');
                $emailErrorMessage.html(errors);
              }
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.contentBlockSignupV1 = {
    attached: false,
    attach: function (context) {
      contentBlockSignup.setup(context);
    }
  };
})(jQuery);
